import { getSequence } from './StoreManager'
import { getLocaleValue } from './localeUil'
import { getUser } from './userLoader'
// import { GET_GEO_SNACKBAR } from '../components/Snackbar/index.js'

export const SET_PROFILE_SNACKBAR = 'profile'
export const GET_GEO_SNACKBAR = 'getGeo'

//уведомление о ошибке
export function showErrorSnackbar(key = 'support_problem_errorApp') {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue(key),
    type: 'error'
  })
}

//уведомление о успехе
export function showSuccessSnackbar(key) {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue(key || 'success'),
    type: 'success'
  })
}

export function showTrashSnackbar(key = 'deleted') {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue(key || 'deleted'),
    type: 'trash'
  })
}

//запрос на пуши
export function askPushSnackbar(uid) {
  let user = getUser(uid)
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue('enable_notifications_popup', { '%userName%': user ? user.name : 'User' }),
    type: 'push'
  })
}

//напоминание о заполнении профиля
export function profileFillSnackbar() {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue('set_profile_notification'),
    type: SET_PROFILE_SNACKBAR
  })
}

//напоминание о GEO разрешении
export function geoRequestSnackbar() {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue('allow_geo_notification'),
    type: GET_GEO_SNACKBAR
  })
}

//todo локализация
//allow_geo_notification
//geo_search_improved
