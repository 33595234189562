import React, { Component } from 'react'
import is from 'is_js'
import { bool, func, number, object,  string} from 'prop-types'
import { connect } from '@cerebral/react'
import { state } from 'cerebral'
import { getDurationStr } from '../utils'
import { getEventAwardEndDate } from '../utils/awards/awardsConfig'
import { getLocaleValue } from '../utils/localeUil'

class TimeLeftString extends Component {
  timer

  componentWillUnmount() {
    if (this.timer){
      clearTimeout(this.timer)
    }
  }

  render() {
    const timeLeft = this.props.endTime - Date.now()

    if(timeLeft < 1000){
      return getLocaleValue(this.props.endStringKey || 'End')
    }
    let updateTime = 1000
    if (timeLeft > 3660000) {
      updateTime = 60000
    }
    this.timer = setTimeout(() => {
      this.forceUpdate()
    }, updateTime)

    if (!this.props.baseStringKey){
      return getDurationStr(timeLeft)
    }
    return getLocaleValue(this.props.baseStringKey, { '{0}': getDurationStr(timeLeft) })
  }
}

TimeLeftString.propTypes = {
  endTime: string,
  endStringKey: string,
  baseStringKey: string
}

export default connect({
  },
  TimeLeftString
)

