import { state } from 'cerebral'

export default [
  ({ store, get, props }) => {
    const popups = get(state`room.popups`)
    const now = Date.now()

    let last = null
    if (popups.length > 0) {
      last = popups[popups.length - 1]
    }

    if (last && last.fromUid === props.fromUid && last.pink === props.pink && last.titles.length < 3) {
      last.titles.push(props.title)
      last.timeStamp = now
    } else {
      popups.push({ 
        fromUid: props.fromUid,
        titles: [props.title],
        pink: props.pink,
        timeStamp: now
      })
    }
    
    store.set('room.popups', popups)

    setTimeout(() => {
      const popups2 = get(state`room.popups`)
      for (let i = 0; i < popups2.length; i++) {
        if (popups2[i].timeStamp === now) {
          popups2.splice(i, 1)
          break
        }
      }
      store.set('room.popups', popups2)
    }, 8000)
  }
]
