import { getState } from '../StoreManager'
import { getAppId } from '../twa'

const IOS_FM_APP_ID = 'com.flirtymania.videochat'
const IOS_UV_APP_ID = 'ulive.tv'

export function isIOSPWA() {
  const appId = getAppId()
  return appId === IOS_FM_APP_ID || appId === IOS_UV_APP_ID
}

export function iosPWABuyClick(params) {
  try {
    window.webkit.messageHandlers.PwaSend.postMessage(
      JSON.stringify( { pay: params })
    )

    // window.webkit.messageHandlers.test.postMessage(JSON.stringify(params))
  } catch(err) {
    alert(err)
    // window.webkit.messageHandlers.test.postMessage(JSON.stringify(params))
  }
}
