import controller from '@/controller'
import { getLocaleValue } from './localeUil'
import { getState } from './StoreManager'

export const COIN_ICON = ''
export const FILLED_COIN = ''
export const SECOND_CURRENCY_ICON = ''
export const LIKE_ICON = ''
export const FILLED_LIKE_ICON = ''

export const VIEW_500_ICON = ''
export const VIEWERS_500_ICON = ''
export const CHAT_500_ICON = ''
export const COIN_500_ICON = ''
export const VIDEO_500_ICON = ''
export const MESSAGE_500_ICON = ''

export const isEmail = email => {
  if (!email) return false
  return email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}

String.prototype.format = function() {
  let a = this
  for (const k in arguments) {
    a = a.replace('{' + k + '}', arguments[k])
  }
  return a
}

export const cutText = (text, length) => typeof text === 'string' && text.length > length ? text.substr(0, length) + '…' : text

export const getTimeAgoText = timestamp => {
  return new Date(timestamp * 1000).toLocaleDateString([controller.getState('intl.lang')], { month: 'long', day:'numeric' })
  //return format(Math.min(Date.now(), timestamp * 1000), controller.getState('intl.lang'))
}

export const getCostNum = baseCost => {
  const pricesRate = controller.getState('auth.pricesRate')
  return Math.floor(baseCost * pricesRate)
}

//возвращает cтоимоcть c наценкой и иконкой монетки
export const getCost = (baseCost, additionalText = null) => {
  if (additionalText) {
    return COIN_ICON + getCostNum(baseCost).toLocaleString() + ' ' + additionalText
  }
  return COIN_ICON + getCostNum(baseCost).toLocaleString()
}

//возвращает cтоимоcть c наценкой и иконкой монетки
export const getCostText500 = (baseCost, additionalText = null) => {
  if (additionalText) {
    return COIN_500_ICON + getCostNum(baseCost).toLocaleString() + ' ' + additionalText
  }
  return COIN_500_ICON + getCostNum(baseCost).toLocaleString()
}

export const getPeepCost = baseCost => {
  const peepMultiplier = controller.getState('intl.settings.peepCost'),
        peepWord = controller.getState('intl.content.peep_button')
  
  return `${COIN_ICON + Math.floor(baseCost * peepMultiplier)} ${peepWord}`
}

export const getPostCost = (post, showCoin) => {
  let cost = 0
  const settings = controller.getState('intl.settings')
  if (post.postType === 1) {
    cost = settings['paidVideoCost_' + post.ageRating] || settings['paidVideoCost']
  } else {
    cost = settings['paidPhotoCost_' + post.ageRating] || settings['paidPhotoCost']
  }
  return showCoin ? getCost(cost) : getCostNum(cost)
}
/**
 * Конвертирует длительноcть в cекундах в человекочитаемый вид
 * @param timeLength - длительноcть в миллиcекундах
 * @return - возвращает cтроку вида  "03:55"
 */
export function getTimeString (timeLength) {
  if (!timeLength) return '00:00'

  const sec_num = timeLength
  let hours   = Math.floor(sec_num / 3600)
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
  let seconds = sec_num - (hours * 3600) - (minutes * 60)

  if (hours   < 10) {hours   = '0' + hours}
  if (minutes < 10) {minutes = '0' + minutes}
  if (seconds < 10) {seconds = '0' + seconds}
  if (hours > 0)
    return hours + ':' + minutes + ':' + seconds
  else
    return minutes + ':' + seconds
}

/**
 * Конвертирует дату в человекочитаемый вид
 * @param timeLength - длительноcть в миллиcекундах
 * @return - возвращает cтроку вида  "03.07"
 */
 export function getDateString (DateTime) {
  if (!DateTime) return null
  const dateTime = new Date(DateTime)
  const months = ['01','02','03','04','05','06','07','08','09','10','11','12']

  const month = months[dateTime.getMonth()]
  const date = dateTime.getDate()
  return date + '.' + month
}

/**
 * Конвертирует длительноcть в миллиcекундах в человекочитаемый вид
 * @param duration - длительноcть в миллиcекундах
 * @param intl - объект cо текcтами локализации
 * @return - возвращает cтроку вида  "3ч 5м"
 */
  export function getDurationStr(duration, intl) {
    intl = getState('intl.content')
    if (!intl || !intl.duration_dh) {
      return ''
    }

    let seconds = Math.floor(duration / 1000) % 60
    let minutes = Math.floor(duration / 60000) % 60
    let hours = Math.floor(duration / 3600000) % 24
    let days = Math.floor(duration / 86400000)

    if (!intl.duration_s) {
      return ''//locales not loaded yet
    }

    seconds = seconds.toLocaleString()
    minutes = minutes.toLocaleString()
    hours = hours.toLocaleString()
    days = days.toLocaleString()

    if (days > 30){
      return getLocaleValue('last_visit_max_date')
    }
    
    if (days > 0) {
      return intl.duration_dh.format(days, hours) 
    }
    if (hours > 0) {
      if (minutes < 10)minutes = '0' + minutes
      return intl.duration_hm.format(hours, minutes) 
    }
    if (minutes > 0) {
      return intl.duration_m.format(minutes) 
    }

    return intl.duration_s.format(seconds) 
  }

export function getPluralForm(key, count, intl) {
  let phrase

  const locale = controller.getState('intl.lang')

  if (locale === 'ru') {
    phrase = getPluralFormRU(key, count, intl)
  } else if (locale === 'ar') {
    phrase = getPluralFormAR(key, count, intl)
  } else if (locale === 'ko' ||
             locale === 'jp') {
    phrase = getPluralForm_JA_KO(key, count, intl)
  }

  if (!phrase) {
    phrase = getPluralFormDefault(key, count, intl)
  }
  if (!phrase) {
    return ''
  }
  
  return phrase.format(count)
}

//japanese and korean
function getPluralForm_JA_KO(key, n, intl) {
  return intl[key]
}

function getPluralFormDefault(key, n, intl) {
  const allForms = intl[key]

  if (!allForms) { return key }

  const forms = allForms.split('|')
  if (n === 1) {
    return forms[0]
  } else {
    return forms[1]
  }
}

function getPluralFormRU(key, n, intl) {
  const allForms = intl[key]

  if (!allForms) { return key }

  const forms = allForms.split('|')
  if (n % 10 === 1 && n % 100 !== 11) {
    return forms[0]
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    return forms[1]
  } else {
    return forms[2]
  }
}

function getPluralFormAR(key, n, intl) {
  const allForms = intl[key]

  if (!allForms) { return key }

  const forms = allForms.split('|')
  if (n === 0) {
    return forms[0]
  } else if (n === 1) {
    return forms[1]
  } else if (n === 2) {
    return forms[2]
  } else if (n % 100 >= 3 && n % 100 <= 10) {
    return forms[3]
  } else if (n % 100 >= 11) {
    return forms[4]
  } else {
    return forms[5]
  }
}

export function replaceOldIntlLinks(localizationKey, searchStr) {
  const rawString = controller.getState(`intl.content.${localizationKey}`)
  const linkName = controller.getState(`intl.content.${searchStr}`)
  return rawString.replace(`%${searchStr}%`, `[${linkName}](${searchStr})`)
}
