import { getSettings } from './settingsUtil'
import { getAppId, getGen, isTWA } from './twa'
import { CHAT_ALL, CHAT_DISABLE, CHAT_PROFILE, CHAT_VIP } from './chat/chatUtil'
import { getState } from './StoreManager'
import { imFemale } from './my'

const defaultConfigTWA = {
  privates_disabled: false,
  chat_mode: CHAT_ALL,
  custom_filters: false,
  payout_disabled: false,
  diversity:false
}

const defaultConfigWEB = {
  privates_disabled: false,
  chat_mode: CHAT_ALL,
  custom_filters: false,
  payout_disabled: false,
  diversity:false
}

export function getAppConfig() {
  console.log('config URL ' + 'mode_' + getAppId() + '_' + getGen())
  return getSettings('mode_' + getAppId() + '_' + getGen()) || (isTWA() ? defaultConfigTWA : defaultConfigWEB)
}

export function isDatingFilterDisable() {
  const config = getAppConfig()
  return config.custom_filters
}

export function isPayoutDisable(onlyConfig) {
  const config = getAppConfig()
  if (config.payout_disabled){
    return config.payout_disabled
  }

  //если я мужик без долларов то кнопки тоже нет
  if (!imFemale() && !getState('auth.usdBalance') && !onlyConfig){
    return true
  }

  return false
}

export function isDiversity() {
  const config = getAppConfig()
  return config.diversity
}
