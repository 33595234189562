import { props, state } from 'cerebral'
import { initABCTest } from '@/utils/GAEvent'
import config from '@/config'
import { getParentDomain } from '@/utils/urlUtils'
import setCrossPromo from '../actions/setCrossPromo'
import controller from '@/controller'
import { getConfigVersion, getLocaleVersion } from '../../../utils/bodyAttributeUtil'
import { set } from 'cerebral/factories'
import { setStoreWrapper } from '../../../utils/StoreManager'
import { saveShopItems } from '../../shop/actions'

const { localesUrl, settingsUrl } = config

const setUIDirection = (locale, store, get) => {
  const availableLocales = get(state`intl.availableLocales`)
  if (!availableLocales[locale]) {
    if (locale === 'ar' || locale === 'he' || locale === 'ur' || locale === 'fa') {
      store.set('intl.direction', 'rtl')
    } else {
      store.set('intl.direction', 'ltr')
    }
  } else {
    const localeFormat = availableLocales[locale].rtl
    if (localeFormat !== undefined) {
      store.set('intl.direction', 'rtl')
    } else {
      store.set('intl.direction', 'ltr')
    }
  }  
}

export const getLangCode = ({ store, get }) => {
  let result = get(state`intl.lang`)
  if (!result) {
    result = navigator.language.substring(0, 2)
    console.log('BROWSER_LANG ' + result)
    store.set('intl.lang', result)
  }
}

const loadSetting = async (url, stateString, store, callback) => {
  try {
    const response = await fetch(settingsUrl + url)
    const result = await response.json()
    store.set(stateString, result)
  } catch (e) {
    console.warn('settings load error', settingsUrl + url, e)
  }
  if (callback) { callback() }
}

const loadSettings = async (get, store) => {
  if (get(state`intl.settings`)) { return }
  
  try {
    const response = await fetch(settingsUrl + '/all-configs' + getConfigVersion())
    const settingsResult = await response.json()
    store.set('intl.settings', settingsResult.settings)
    store.set('intl.broadcastThemes', settingsResult.scenarios)
    store.set('intl.postCategories', settingsResult.categories)
    //intl.postKinds.${state`upload.postKind`}.categoryIds

    const categoryIds = [
      'people',
      'profile_languages',
      'videochat_questionnaire_purpose',
      'profile_housing',
      'profile_kids',
      'profile_education',
      'profile_religion',
      'profile_smoking',
      'profile_height',
      'videochat_questionnaire_interests',
      'videochat_questionnaire_job'
    ]
    const postProfile = {
      kindId: 'postProfile',
      categoryIds: categoryIds,
      autoTags: [
        1067952
      ],
      subjects: [],
      priority: 0
    }
    settingsResult.kind['postProfile'] = postProfile
    store.set('intl.postKinds', settingsResult.kind)
    store.set('intl.stopWords', settingsResult.stop_words)
    store.set('intl.spam', settingsResult.info)
    store.set('intl.trustedDomains', settingsResult.trusted_domains || [])
    store.set('intl.availableLocales', settingsResult.locales)
    // store.set('intl.domainsConfig', settingsResult.domains)

    if (settingsResult.settings && settingsResult.settings.coins_items) {
      setStoreWrapper('shop.successfullyLoaded', true)
      setStoreWrapper('shop.items', saveShopItems(settingsResult.settings.coins_items))
    } else {
      setStoreWrapper('shop.successfullyLoaded', false)
    }
    //coins_items

    //если логин был ДО того как приешл конфиг с балансом обновим долларовый баланс
    const receivedCoins = get(state`auth.receivedCoins`)
    if (receivedCoins) {
      //обновим баланс долларов
      const coinsToMoney = get(state`intl.settings.coinsToMoney`)
      store.set('auth.usdBalance', Math.trunc(receivedCoins / coinsToMoney * 100) / 100)
      console.log('USD updated')
    }

    //store.set('intl.spam', settingsResult.info)
    // loadSetting('/info', 'intl.spam', store)
    // //store.set('intl.trustedDomains', settingsResult.trusted_domains || [])
    // loadSetting('/trusted-domains', 'intl.trustedDomains', store)
    // //store.set('intl.availableLocales', settingsResult.locales)
    // loadSetting('/locales', 'intl.availableLocales', store)
    // //store.set('intl.domainsConfig', settingsResult.domains)
    // await loadSetting('/domains', 'intl.domainsConfig', store, () => setCrossPromo({ store, get }))
    
    initABCTest({ store, get })
  } catch (e) {
    console.warn('settings load error', settingsUrl + '/all-configs', e)
  }
}

const loadLocale = async ({ locale, skin }) => {
  try {
    const response = await fetch(localesUrl + `/api/v1/result/${skin || 'main'}/${locale}.json` + getLocaleVersion())

    if (response.ok) {
      return await response.json()
    } else {
      throw new Error('locale load error')
    }
  } catch (e) {
    console.warn('Locale load error', locale, skin)
    if (skin) {
      return await loadLocale({ locale })
    }
    if (locale !== 'en') {
      return await loadLocale({ locale: 'en' })
    }
    
    return {}
  }
}

const startLocaleLoading = async ({ store, get, props }) => {
  const locale = get(state`intl.lang`)
  
  await loadSettings(get, store)

  setUIDirection(locale, store, get)
  
  const ageRating = get(state`app.ageRating`)
  const domainsConfig = get(state`intl.domainsConfig`)
  let skin = null
  if (domainsConfig && domainsConfig[getParentDomain()]) {
    skin = domainsConfig[getParentDomain()].textSkinId
  }
  if (!skin) {
    skin = 'main'
  }

  const localeData = await loadLocale({ locale, skin })
  
  store.merge('intl.content', localeData)
}

export const getLocaleRequest = async ({ store, get, path, props }) => {
  const localization = get(state`intl.content`)

  if (localization && props.useCache) {
    store.merge('intl.content', localization)
    startLocaleLoading({ store, get, props })

    return path.success()
  }

  await startLocaleLoading({ store, get, props })
  return path.success()
}
