import React, { Component } from 'react'
import LevelShape from './LevelShape'
import { getMyUid } from '../../utils/my'
import { array, number, string, object } from 'prop-types'
import { state } from 'cerebral'
import AnimatedShape from './AnimatedShape'
import { connect } from '@cerebral/react'
import { isUID } from '../../utils/isUID'

class AnimatedField extends Component {

  state ={
    likes: [],
    renderDate: null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if (prevProps.animatedLikes === this.props.animatedLikes){
    //   return
    // }
    const {
      animatedLikes
    } = this.props

    const renderDate = this.state.renderDate
    const likes = this.state.likes
    let needUpdate
    animatedLikes.forEach(likeObj => {
      if(isUID(likeObj.uid) && (!renderDate || renderDate < likeObj.date)){
        needUpdate = true
        likes.push(<AnimatedShape size={28} uid={likeObj.uid} date={likeObj.date} duration={2900} />)
      }
    })
    
    const newLikes = []
    likes.forEach(rendered =>{
      if (rendered){
        newLikes.push(rendered)
      } else {
        needUpdate = true
      }
    })

    if (needUpdate){
      this.setState({ likes: newLikes, renderDate: Date.now() })
    }
  }

  render() {
    return(
      <div style={{ position: 'absolute', pointerEvents: 'none' }}>
        {this.state.likes}
      </div>
    )
  }
}

AnimatedField.propTypes = {
  animatedLikes: array
}

export default connect({
  animatedLikes: state`app.animatedLikes`,
}, AnimatedField)
