import { getMyUid, isNotificationEnable } from '../../../utils/my'
import { getMyLSProp, setMyLSProps } from '../../../utils/LocalStorageUtil'
import { askPushSnackbar, geoRequestSnackbar, profileFillSnackbar } from '../../../utils/snackbarUtil'
import { getProfilePostId } from '../../../utils/userUtil'
import { isBrowserGeoSet } from '../../../utils/geo/geoUtil'
import { state } from 'cerebral'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { getLocaleValue } from '../../../utils/localeUil'
import { MESSAGE_TYPE_SYSTEM } from '../../../utils/manyConst'
import { isPayoutDisable } from '../../../utils/appConfigUtil'
import { createFirstIncomePanel } from '../../../components/Panels/createPanelUtil'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'

const NOTIFICATION_ASK_TIMEOUT = 24*60*60*1000
const GEO_ASK_TIMEOUT = 24*60*60*1000
const FILL_PROFILE_ASK_TIMEOUT = 12*60*60*1000

//таймаут между уведомлениями
const TIMEOUT = 30*1000

const NOTIFICATION  = 'askNotificationDate'
const PROFILE_NOTIFICATION  = 'askFillProfileDate'
const GEO_ASK_NOTIFICATION  = 'askGEODate'

const NOTIFICATION_TYPES = [NOTIFICATION, PROFILE_NOTIFICATION, GEO_ASK_NOTIFICATION]

export function checkNotification(uid) {
  if (!isNotificationEnable()){
    return
  }

  if (!checkAnyNotification()){
    return
  }

  let askNotificationDate = getMyLSProp(NOTIFICATION) || 0

  if (!askNotificationDate || (askNotificationDate + NOTIFICATION_ASK_TIMEOUT) < Date.now()){
    askPushSnackbar(uid)
    setMyLSProps(NOTIFICATION, Date.now())
  }
}

export function checkFillProfileNotification() {
  if (getProfilePostId(getMyUid())){
    return
  }

  if (!checkAnyNotification()){
    return
  }

  let askNotificationDate = getMyLSProp(GEO_ASK_NOTIFICATION) || 0

  if (!askNotificationDate || (askNotificationDate + GEO_ASK_TIMEOUT) < Date.now()){
    profileFillSnackbar()
    setMyLSProps(GEO_ASK_NOTIFICATION, Date.now())
  }
}

export function checkGEONotification() {
  if (isBrowserGeoSet()){
    return
  }

  if (!checkAnyNotification()){
    return
  }

  let askNotificationDate = getMyLSProp(GEO_ASK_NOTIFICATION) || 0

  if (!askNotificationDate || (askNotificationDate + FILL_PROFILE_ASK_TIMEOUT) < Date.now()){
    geoRequestSnackbar()
    setMyLSProps(PROFILE_NOTIFICATION, Date.now())
  }
}

function checkAnyNotification() {
  let lastNotification = 0
  let currentType = 0
  NOTIFICATION_TYPES.forEach(type => {
    currentType = getMyLSProp(type) || 0
    if (currentType > lastNotification){
      lastNotification = currentType
    }
  })

  if (lastNotification && (lastNotification + TIMEOUT) < Date.now()){
    return false
  }

  return true
}

const chatNotifications = {}

export function chatNotification(key) {
  if (chatNotifications[key]){
    return
  }

  chatNotifications[key] = true
  const roomId = getState('chat.chatRoomId')
  const allMessages = getState('chat.roomsMessages') || {}
  const messages = allMessages[roomId] || []

  messages.push({
    text: key,
    type: MESSAGE_TYPE_SYSTEM,
    messageOriginal: key
  })

  allMessages[roomId] = messages
  setStoreWrapper('chat.roomsMessages', allMessages)
}

export function checkIncomeNotification(){
  if (isPayoutDisable(true) || getState('auth.usdBalance')){
    return
  }
  sendAmplitudeEvent('first_income_panel')
  createFirstIncomePanel()
}
