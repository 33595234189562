import { state } from 'cerebral'
import { send } from '../../socket/actions'

export default [
  ({ props }) => ({
    params: {
      postId: props.post,
      fileId: props.fileId,
      timestamp: parseInt(props.postId, 36) / 1000
    },
    method: 'deleteComment'
  }),
  send,
  ({ store, get, props }) => {
    const postComments = get(state`chat.dialogs.${props.post}`)
    const newPostComments = postComments.filter(
      comment => comment.postId !== props.postId
    )
    store.set('chat.dialogs.' + props.post, newPostComments)
  }
]
