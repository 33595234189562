import { getSettings } from '../settingsUtil'
import { imAdmin } from '../adminUtil'
import { imFemale } from '../my'
import React from 'react'
import styled from '@emotion/styled'
import propsOverrideCss from '@/utils/propsOverrideCss'
import { getLocaleValue } from '../localeUil'
import { getDurationStr } from '../stringUtils'
import { getState, setStoreWrapper } from '../StoreManager'
import TimeLeftString from '../../ui/TimeLeftString'
import { serverTimeToLocalTime } from '../serverTimeUtil'
import { isAwardUnlocked } from './awardsProgressUtil'
// import { VipLiveLbl } from './../components/Screens/Posters/Poster'
// import { VipLiveLbl } from './.././Screens/Posters/Poster'

const VipLiveLbl = styled.span({
  // lineHeight: '20px',
  pointerEvents: 'none',
  fontSize: 14,
  height:20,
  fontWeight: 400,
  whiteSpace: 'nowrap',
  textShadow: '0 0 2px rgba(0, 0, 0, 0)',
  color: '#fff',
  backgroundImage: 'linear-gradient(to right, #fab62a, #f38b1f)',
  borderRadius: 10,
  padding: '1px 8px',
  // position: 'absolute',
  // top: 8,
}, propsOverrideCss)
export function getAwardsConfig() {
  return getSettings('awards') || {}
}

export function getAwardConfig(awardId) {
  const allAwards = getAwardsConfig()
  return allAwards[awardId]
}

let prepared = false

let awardsByTypes = {}

export const AWARD_VIP_USER = 'vip_user_crown'
export const TEST_AWARD = 'test'
export const CHARACTER_AWARD = 'heroes'
export const LOCATION_AWARD = 'location'
export const PET_AWARD = 'pet'
export const SPECIAL_AWARD = 'special'

export const ALL_FILTER = 'all_option'
export const FREE_FILTER = 'free_cost'
export const EVENT_FILTER = 'event'
export const VIP_FILTER = 'VIP'
export const PAID_FILTER = 'premium'
export const UNLOCKED_FILTER = 'unlocked'
export const NEW_FILTER = 'new'
export const EPIC_FILTER = 'epic'

export function prepareAwardConfig(force) {
  if (prepared && !force){
    return
  }
  awardsByTypes = {}

  const allAwards = getAwardsConfig()

  Object.keys(allAwards).map(awardId =>
  {
    prepared = true
    const award = allAwards[awardId]

    if (award.visibility === TEST_AWARD && !imAdmin()){
      return
    }
    let category = award.category
    if (!awardsByTypes[category]){
      awardsByTypes[category] = []
    }

    if (isAwardUnlocked(awardId) || canBuyAward(awardId) || isVIPAward(awardId)){
      awardsByTypes[category].push(awardId)
    } else {
      console.log('award')
      isAwardUnlocked(awardId)
    }
  })

  //отсортируем все массивы
  Object.keys(awardsByTypes).map(category =>
  {
    const awardArray = awardsByTypes[category]
    awardArray.sort((awardId1, awardId2)=>
    {
      const aw1 = allAwards[awardId1]
      const aw2 = allAwards[awardId2]
      if (aw1.rarity === EVENT_AWARD && aw2.rarity !== EVENT_AWARD ){
        return -1
      }

      if (aw1.rarity !== EVENT_AWARD && aw2.rarity === EVENT_AWARD ){
        return 1
      }

      // const unloked1 = isAwardUnlocked(awardId1)
      // const unloked2 = isAwardUnlocked(awardId2)
      // if (unloked1 && !unloked2){
      //   return 1
      // }
      //
      // if (!unloked1 && unloked2){
      //   return -1
      // }

      if (aw1.paid && !aw2.paid){
        return 1
      }
      if (!aw1.paid && aw2.paid){
        return -1
      }

      if (aw1.vip && !aw2.vip){
        return 1
      }
      if (!aw1.vip && aw2.vip){
        return -1
      }

      return aw1.rating - aw2.rating
    })
  })

  setStoreWrapper('awardsByTypes', awardsByTypes)
}

export function getAllFreeFemaleCharacters() {
  return getAllGenderCharacter(true, FREE_FILTER)
}

export function getAllFreeMaleCharacters() {
  return getAllGenderCharacter(false, FREE_FILTER)
}

export function getVIPCharacters(female) {
 return getAllGenderCharacter(female, VIP_FILTER)
}

export function getAllGenderCharacter(female, filter) {
  prepareAwardConfig()

  const allCharacters = getAllCharactersByGender()
  const allAwards = getAwardsConfig()

  let filtered = allCharacters.filter(awardId => {
    if (female){
      return allAwards[awardId].female
    }
    return !allAwards[awardId].female
  })
  return applyFilter(filtered, filter)
}

export function getAllCharactersByGender(filter) {
  prepareAwardConfig()
  const characters = getAwardsByCategory(CHARACTER_AWARD, filter)
  const allAwards = getAwardsConfig()
  const female = imFemale()
  return characters.sort((awardId1, awardId2)=> {
    const aw1 = allAwards[awardId1].female ? 1 : 0
    const aw2 = allAwards[awardId2].female ? 1 : 0
    if (!female){
      return aw1 - aw2
    } else {
      return aw2 - aw1
    }
  })
}

export function getAwardsByCategory(category, filter) {
  prepareAwardConfig()
  const awards = awardsByTypes[category] || []
  if (!filter || filter === ALL_FILTER){
    return awards
  }

  return applyFilter(awards, filter)
}

export function onlyAvailableNow(awards) {
  prepareAwardConfig()
  return awards.filter(awardId => {
    return isAwardUnlocked(awardId) || canBuyAward(awardId)
  })
}

export function isCharacter(id) {
  return getAwardCategory(id) === CHARACTER_AWARD
}

export function isLocation(id) {
  return getAwardCategory(id) === LOCATION_AWARD
}

export function getAwardCategory(id) {
  const allAwards = getAwardsConfig()
  if (!allAwards[id]){
    return 'unknown'
  }
  return allAwards[id].category
}

export function canBuyAward(id) {
  const allAwards = getAwardsConfig()
  const endDate = getEventAwardEndDate(id)
  if (endDate !== -1){
    return endDate - Date.now() > 0
  }
  return allAwards[id] && allAwards[id].paid
}

export function isVIPAward(id) {
  const allAwards = getAwardsConfig()
  return allAwards[id] && allAwards[id].vip
}

const VIP_AWARD = 'VIP'
const EPIC_AWARD = 'EPIC'
const SIMPLE_AWARD = 'simple_award'
const EVENT_AWARD = 'event_award'

export function getRareType(id) {
  if (isVIPAward(id)){
    return VIP_AWARD
  }

  const allAwards = getAwardsConfig()
  if (allAwards[id] && allAwards[id].rarity){
    return allAwards[id].rarity
  }

  return SIMPLE_AWARD
}

export function getEventAwardEndDate(id) {
  const allAwards = getAwardsConfig()
  if (allAwards[id] && allAwards[id].rarity === EVENT_AWARD){
    return serverTimeToLocalTime(allAwards[id].endDate)
  }

  return -1
}

export function getEventAwardName(id) {
  const duration = getEventAwardEndDate(id)

  const awardConfig = getAwardConfig(id)
  let endString = 'EVENT'
  if (awardConfig && awardConfig.rarity === EVENT_AWARD && awardConfig.eventName){
    endString = awardConfig.eventName
  }

  if (awardConfig.howToGet === 'top_hitting_local' || awardConfig.howToGet === 'top_hitting_global'){
    return getLocaleValue('filter_videochat_top_monthly')
  }
  if (awardConfig.howToGet === 'top_winner_local' || awardConfig.howToGet === 'top_winner_global'){
    return getLocaleValue(awardConfig.howToGet)
  }
  if (duration !== -1 && !isAwardUnlocked(id)){
    return <TimeLeftString endTime={getEventAwardEndDate(id)} endStringKey={endString} baseStringKey={'time_left'} /> //getDurationStr(Date.now() - )
  }
  return getLocaleValue(endString)
}

export function awardHowToGet(id) {
  const awardConfig = getAwardConfig(id)
  if (awardConfig && awardConfig.howToGet){
    return awardConfig.howToGet
  }

  return null
}

export function getRareCSSAward(id) {
  const rare = getRareType(id)
  if (rare === VIP_AWARD){
    return 'awardGlowGold'
  }

  if (rare === EPIC_AWARD){
    return 'awardGlowEpic'
  }

  if (rare === EVENT_AWARD){
    return 'awardGlowEvent'
  }

  return 'awardGlowNormal'
}

export function getRareBadge(id) {
  const rare = getRareType(id)
  if (rare === VIP_AWARD){
    return  (<VipLiveLbl left={8}>
      {rare}
    </VipLiveLbl>)
  }

  if (rare === EVENT_AWARD){
    return (<VipLiveLbl style={{
      backgroundImage: 'linear-gradient(to bottom, #f419a6 -13%, #bc0fe0 97%), linear-gradient(to bottom, #ff5885, #ff5885)'
    }} left={8}>
      {getEventAwardName(id)}
    </VipLiveLbl>)
  }

  if (rare === EPIC_AWARD){
    return (<VipLiveLbl style={{ backgroundImage: 'linear-gradient(to bottom, #f419a6 -13%, #bc0fe0 97%), linear-gradient(to bottom, #ff5885, #ff5885)'}} left={8}>
      {rare}
    </VipLiveLbl>)
  }

  return null
}

function applyFilter(awards, filter){
  const allAwards = getAwardsConfig()
  let filtered = awards
  if (filter === FREE_FILTER){
    filtered = awards.filter(awardId => {
      return !allAwards[awardId].paid && !allAwards[awardId].vip
    })
  }

  if (filter === EVENT_FILTER){
    filtered = filtered.filter(awardId => {
      return allAwards[awardId].rarity === EVENT_AWARD
    })
  }

  if (filter === VIP_FILTER){
    filtered = filtered.filter(awardId => {
      return allAwards[awardId].vip
    })
  }

  if (filter === UNLOCKED_FILTER){
    filtered = filtered.filter(awardId => {
      return allAwards[awardId].paid && !allAwards[awardId].vip && isAwardUnlocked(awardId)
    })
  }
  if (filter === PAID_FILTER){
    filtered = filtered.filter(awardId => {
      return allAwards[awardId].paid && !allAwards[awardId].vip && !isAwardUnlocked(awardId)
    })
  }

  if (filter === EPIC_FILTER){
    filtered = filtered.filter(awardId => {
      return allAwards[awardId].rarity === EPIC_AWARD
    })
  }
  return filtered
}
