import React, { Component } from 'react'
import LevelShape from './LevelShape'
import { getMyUid } from '../../utils/my'
import { number, string, object } from 'prop-types'
import { state } from 'cerebral'
import { getLevel } from '../../utils/levelUtil'

class AnimatedShape extends Component {

  state = {
    visible: true
  }

  animateInterval
  // direction = 1
  // frames = 0
  componentDidMount() {
    setTimeout(()=>{
      this.setState({ visible: false })
    }, this.props.duration)
    // this.animateInterval = setInterval(()=>{
    //   let frames = this.state.frames + 1
    //   let directionLeft = this.state.directionLeft
    //   if (frames > Math.max(30,Math.random()*50)){
    //     directionLeft = !this.state.directionLeft
    //     frames = 0
    //   }
    //   const stepSwing = 0.5//this.props.size*2
    //
    //   // const maxSwing = this.props.size*3
    //   // let swing = stepSwing * Math.random()
    //   let x = this.state.x + (directionLeft ? stepSwing : -stepSwing)
    //
    //   let y = this.state.y + 1.5//this.props.size
    //   this.setState({ x, y, frames, directionLeft })
    // }, 5)
  }

  componentWillUnmount() {

  }

  render() {
    const {
      size,
      uid
    } = this.props
    if (!this.state.visible){
      return null
    }
    const swingType = 'swing'+ Math.ceil(Math.random()*3) + ' ' + Math.max(Math.random()*1, 0.3) + 's alternate infinite ease-in-out'
    const lvl = getLevel(uid)
    return(
      <div style={{ position: 'absolute', animation: 'flyUpOnLike 3s', transform: 'translate(-50%, -50%)' }}>
        {(lvl > 0) ?
          <LevelShape uid={uid} size={size} noStars={true} noVerify={true} style={{ animation: swingType }}/>
          :
          <div style={{ animation: swingType, minWidth:size, minHeight:size, fontSize: 16,
            display: 'flex',
            justifyContent: 'center',
            alignContent:'center',
            alignItems: 'center',
            lineHeight: '1em',
            fontStretch: 'normal',
            fontStyle: 'normal',
            // backgroundColor: '#f6f6f6'
          }}>
            <i className={'icon-flirty-like-full buttonIcon filledLikeIcon'} />
          </div>
        }
      </div>
    )
  }
}

AnimatedShape.propTypes = {
  size: number,
  uid: string,
  date: object,
  duration: number
}

export default AnimatedShape
