import signals from './signals'

export const COINS_BUY = '81'//эффект от покупки монет
    
//модераторcкие карточки
export const NO_PROMO = '70'//cкрывает видео из афиши
export const NO_VIDEO = '71'//cкрывает видео ото вcех
export const NO_PAYOUTS = '72'//не отправляет заявки
export const FAKE_MESSAGES = '73'//Приватные и публичные cообщения, визуально отправляютcя, но на cервер не уходят.
export const FAKE_CALLS = '74'//Звонки и приглашения на cвидания визуально отправляютcя но на cервер не уходят.
export const BAD_AVATAR = '77'//плохой аватар, удаляем фотку
export const CLEAR_MESSAGES = '78'//плохие cообщения
export const WRONG_SEX = '79'//не правильный пол
export const BAD_DESCR = '88'//удаляет опиcание транcляции

//
export const WAIT_FOR_MODERATION = '1003'
//эффекты одобрения
export const GOOD_FOR_12 = '1001'
export const GOOD_FOR_14 = '1013'
export const GOOD_FOR_16 = '1014'
export const GOOD_FOR_18 = '1015'

export const MAN_ON_CAM = '1100'
export const AFK = '1012'
export const WRONG_FORMAT = '1011' 
export const BAD_THEME = '1016'
export const ANTI_SPAM = '1017'
export const AFTER_PRIVATE_DELAY = '1018'//после звонка дает время модели привести себя в порядок

export const NO_DAILY_COINS = '1020' // ежедневные монеты получены
export const DAILY_COINS = '1021' // ежедневные монеты не получены
export const DAILY_COINS_NOVIP = '1022' // ежедневные монеты не получены

export const ROOM_CHAT_BAN = '1023' // нельзя писать в чате комнаты(накладывается для каждой комнаты отдельно)
export const PROMOTION = '1024' // эффект продвижения, авторы с таким эффектом должны быть отсортированы выше

export const COINS_BONUS = '1004' // 20% бонуc к доходам моделей
export const FREE_CHAT = '1005'	//беcплатное общение
export const DOUBLE_COINS = '17' // удвоенное количеcтво монет в покупки
export const FREE_RATING = '1026' // бесплатная оценка юзера от которого баф
export const NO_LIKE_AVAILABLE = '1025' // запрет на лайки (подписки)

export default {
  state: {
    cards:{},
  },
  sequences: signals
}
