import { state } from 'cerebral'
import getMsgPrice from '@/utils/getMsgPrice'
import { GOOD_FOR_12, GOOD_FOR_14, GOOD_FOR_16, GOOD_FOR_18 } from '@/modules/buffs'
import { isBuffActive } from '@/utils/buffUtils'
import controller from '@/controller'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getUser } from '@/utils/userLoader'
import { CHAT_PERMISSION, PRIVATE_MSG_PERMISSION, COMMENT_PERMISSION } from '@/utils/subscriptionUtil'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { getOnlineStatusForAmplitude, getStreamStatusForAmplitude } from '../../socket/actions'
import { getChatDraft, setChatDraft } from '../../../utils/inputUtil'
import { isChatAvailable } from '../../../utils/chat/chatUtil'
import { getMyUid } from '../../../utils/my'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import askRateApp from '../../../utils/askRateApp'

export default ({ props, store, get }) => {
  const currentText = getChatDraft(props.chatKey)
  if (!currentText && !props.stickerId) {
    return
  }

  if (!isChatAvailable(true)){
    return
  }
  
  props.params = {
    message: {
      text: currentText,
      from: get(state`auth.uid`),
      fromId: getMyUid(),
      messageOriginal: currentText
    },
    roomId: get(state`chat.chatRoomId`)
  }

  const price = getMsgPrice()
  
  if (price > 0) {
    props.params.cost = price
  }
  
  if (props.stickerId) {
    props.params.message.sticker = props.stickerId
    props.params.message.text = props.stickerId
  } else {
    setChatDraft(props.chatKey,  '')
  }

  const panels = get(state`app.panels`) || []
  let isPrivate = false
  if (panels.length > 0) {
    if (panels[panels.length - 1].type === 'dialog') {
      isPrivate = true
    }
    if (panels.length > 1) {
      if (panels[panels.length - 2].type === 'dialog') {
        isPrivate = true
      }
    }
  }
  
  if (isPrivate) {
    const dialogUserId = get(state`app.dialogUserId`)
    props.method = 'privateMessage'
    props.params.message.to = dialogUserId
    props.params.message.private = true
  } else {
    props.method = 'chatMessage'
    props.roomId = props.params.roomId
    if (get(state`chat.toId`) !== '') {
      props.params.message.to = get(state`chat.toId`)
    }
  }
  //дублируем информацию в props.msgData, чтобы cработал onNewMessage
  //и cообщение показалоcь, не дожидаяcь ответа от cервера
  props.msgData = props.params.message

  let messageCounter = getState('messageCounter') || 0
  messageCounter++
  setStoreWrapper('messageCounter', messageCounter)
  //если отправил больше 5 сообщений просим оценку
  if (messageCounter > 5){
    askRateApp('messages')
  }
  sendAnalyticsEvent(props)
}

function sendAnalyticsEvent(props) {
  const user = getUser(props.params.roomId || props.params.message.to) || {}
  const amData = {
    sent_coins: props.params.cost,
    online: getOnlineStatusForAmplitude(props.params.roomId || props.params.message.to),
    stream: getStreamStatusForAmplitude(props.params.roomId || props.params.message.to),
    age_rating: getUserAgeRating(props.params.roomId || props.params.message.to),
    subscription_type: '-1',
    counterparty_id: props.params.roomId || props.params.message.to || '-1',
  }

  if (props.params.message.private) {
    amData.message_category_1 = 'send_private'
    if (user.subscriptionSettings) {
      amData.subscription_type = user.subscriptionSettings[PRIVATE_MSG_PERMISSION] || '-1'
    }

    if (isBuffActive(props.params.message.to, GOOD_FOR_16) ||
        isBuffActive(props.params.message.to, GOOD_FOR_18)) {
      amData.message_category_2 = 'privateMsgToBroadcasterCostPlus'
      sendAmplitudeEvent('message', amData)
    } else if (isBuffActive(props.params.message.to, GOOD_FOR_12) || 
               isBuffActive(props.params.message.to, GOOD_FOR_14)) {
      amData.message_category_2 = 'privateMsgToBroadcasterCost'
      sendAmplitudeEvent('message', amData)
    } else {
      amData.message_category_2 = 'privateMsgCost'
      sendAmplitudeEvent('message', amData)
    }
  } else {
    amData.message_category_1 = 'send_message'
    if (user.subscriptionSettings) {
      amData.subscription_type = user.subscriptionSettings[CHAT_PERMISSION] || '-1'
    }

    if (controller.getState('app.route') === 'home') {
      amData.message_category_2 = 'main_chat'
      sendAmplitudeEvent('message', amData)
    } else {
      amData.message_category_2 = 'roomMsg'
      sendAmplitudeEvent('message', amData)
    }
  }
}
