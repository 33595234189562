import { setMyProfileId } from '../my'

const { userloaderUrl } = config
import config from '@/config'
import { callSequence, getState, setStoreWrapper } from '../StoreManager'
import { initGA } from '../GAEvent'
import { prepareAwardConfig } from '../awards/awardsConfig'
import { FEMALE } from '../postUtils'
import { isPanelType } from '../../modules/app/actions'
import { closeCurrentPanel } from '../../components/Panels/createPanelUtil'
import { clearAllPosters } from '../posters/clearAllPosters'

export async function loadMyData(uid, params, get) {
  try {
    const response = await fetch(userloaderUrl + '/api/v1/userloader', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ uids: [uid] })
    })

    if (response && response.ok) {
      const respObj = await response.json()
      const userData = { ...params, ...respObj[uid] }
      userData.name = getState('auth.name')
      userData.multi_buffs = getState('auth.multi_buffs')
      userData.storage = getState('auth.storage')
      const allUsers = getState('app.users') || {}

      allUsers[uid] = userData
      setStoreWrapper('app.users', allUsers)
      if (userData.gender === 'female'){
        setStoreWrapper('auth.female', true)
      } else if (!userData.gender || userData.gender === 'male') {
        setStoreWrapper('auth.female', false)
      }

      initGA({ get })
      if (userData && userData.profile_post && userData.profile_post.postId){
        setMyProfileId(userData.profile_post.postId)
      } else if (userData && userData.storage && userData.storage.profilePost && userData.storage.profilePost.postId){
        setMyProfileId(userData.storage.profilePost.postId)
      }
      setStoreWrapper('allMyDataLoaded', true)
      setStoreWrapper('genderSelectScreen', false)
      if (isPanelType('genderSelectPanel')) {
        // closeCurrentPanel()
        callSequence('app.destroyPanelImmediately')
      }

      clearAllPosters()
      setStoreWrapper('auth.isAuthComplete', true)
      callSequence('auth.loadSpam')
      prepareAwardConfig(true)
    }
  } catch (e) {
    console.log('@@@ myDataLoadError', e)
  }
}
