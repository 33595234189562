import { getState, setStoreWrapper } from '../StoreManager'
import { getMyUid, imVIP } from '../my'
import { getUser } from '../userLoader'
import { checkDialogMsgPermission, needSubscription, PRIVATE_MSG_PERMISSION } from '../subscriptionUtil'
import { getFanInfo } from '../../modules/fans/fansUtil'
import { checkAdmin, imAdmin, isSupport } from '../adminUtil'
import { sendAmplitudeEvent } from '../GAEvent'
import { getDialogs } from '../../modules/chat/actions/sendDialogMessage'
import { MESSAGE_TYPE_SYSTEM } from '../manyConst'
import { isMe } from '../checks'

export function getDialogById(dialog_id) {
  const dialogs = getState('chat.dialogs')
  return dialogs && dialogs.find(d => d.dialog_id === dialog_id)
}

export function updateDialogFileData(dialog_id, messageId, fileInfo) {
  // const dialogs = getState('chat.dialogs')

  const dialog = getDialogById(dialog_id)

  if (dialog && dialog.messages){
    let msg
    for (let i = 0; i < dialog.messages.length; i++) {
      msg = dialog.messages[i]
      if (msg.message_id === messageId) {
        const msgNew = msg
        msgNew.metadata.file_info = fileInfo
        if (!msgNew.metadata.paid_info){
          msgNew.metadata.paid_info = {}
        }
        msgNew.metadata.paid_info.already_paid_uids.push(getMyUid())
        dialog.messages.splice(i,1, msgNew)
        // dialog.messages[i] = msgNew
        // setTimeout(() =>{
        //   setStoreWrapper('chat.buyingMedia', -1)
        // }, 500)

        return
      }
    }
    // .forEach(msg => {

    // })
    // dialog.messages.map((m, idx) => {
    //   if (m.message_id === messageId){
    //     m.metadata.file_info = fileInfo
    //     m.metadata.paid_info.already_paid_uids.push(getMyUid())
    //
    //     setStoreWrapper('chat.buyingMedia', -1)
    //     return
    //   }
    // })
  }

  // setStoreWrapper('chat.dialogs', dialogs)
}

export function isPaidDialogMessage(uid, dialog_id, noVip) {
  //если какое то не стандратное ограничение, то нет блока про цену
  if (needSubscription(uid, PRIVATE_MSG_PERMISSION)){
    if (noVip){
      return false
    }
    return !imVIP()
  }

  if (isSupport(uid)){
    return false
  }

  if (imAdmin()){
    return false
  }

  if (getFanInfo(uid)){
    return false
  }

  const messages = getDialog(dialog_id, uid).messages
  //если есть сообщения в диалоге и не системное, значит бесплатно
  if (messages.length && messages.find(message => message.message_type !== MESSAGE_TYPE_SYSTEM)){
    return false
  }

  return true
}

let newDialogsMap = {}
export function addUnreadDirectMessage(dialog_id, count) {
  newDialogsMap[dialog_id] = (newDialogsMap[dialog_id] || 0) + count
  setStoreWrapper('unreadDialogMessage',(getState('unreadDialogMessage') || 0) + count)
}

export function clearUnreadDirectMessage() {
  newDialogsMap = {}
  setStoreWrapper('unreadDialogMessage', null)
}

export function markReadUnreadDirectMessage(dialog_id) {
  if (getState('unreadDialogMessage')) {
    let currentMessage = newDialogsMap[dialog_id]
    if (currentMessage) {
      let unread = getState('unreadDialogMessage')
      if (unread >= currentMessage) {
        setStoreWrapper('unreadDialogMessage', (unread - currentMessage))
      }
      delete newDialogsMap[dialog_id]
    }
  }
}

export function getUnreadMessageCount(dialog_id) {
  return newDialogsMap[dialog_id] || 0
}

export function getDialogCost() {
  const settings = getState('intl.settings')
  if (!settings){
    return 95
  }
  return settings.dialogMessageCost || 95
}

export function getDialog(dialog_id, uid, otherUid) {
  if (dialog_id === 'AllDialogsMedia' || otherUid){
    const od = getState('chat.otherDialogs') || {}
    if (dialog_id === 'AllDialogsMedia') {
      if (od[dialog_id]) {
        return od[dialog_id]['dialogs'][0]//   od[props.otherUid] = { dialogs: [] }
      }
    }
    if (otherUid){
      return od[otherUid]['dialogs'].find(
        dialog_id ?
          d => d.dialog_id === dialog_id :
          d => d.users_in_dialog.length === 2 && (d.users_in_dialog[0] === uid || d.users_in_dialog[1] === uid)
      )
    }
  }

  const dialogs = getDialogs()
  let dialog = dialogs.find(
    dialog_id ?
      d => d.dialog_id === dialog_id :
      d => d.users_in_dialog.length === 2 && (d.users_in_dialog[0] === uid || d.users_in_dialog[1] === uid)
  )
  if (!dialog) {
    dialog = {
      date: new Date().toISOString(),
      users_in_dialog: isMe(uid) ? [uid] : [
        getMyUid(),
        uid
      ],
    }
    dialogs.push(dialog)
  }

  if (!dialog.messages) {
    dialog.messages = []
  }

  return dialog
}
