import controller from '@/controller'
import storeShiftUtil from './signals/StoreShiftUtil'

export function setStoreWrapper(key, value) {
  try{
    const storeSetUtil = getSequence('app.storeSetUtil')
    storeSetUtil({ key: key, value: value })
  }catch (e) {
   console.error('storeSetUtil ' + e)
  }
}

export function pushStoreWrapper(key, value) {
  try{
    const storeSetUtil = getSequence('app.storePushUtil')
    storeSetUtil({ key: key, value: value })
  }catch (e) {
    console.error('storePushUtil ' + e)
  }
}

export function shiftStoreWrapper(key) {
  try{
    const storeUtil = getSequence('app.storeShiftUtil')
    storeUtil({ key: key })
  }catch (e) {
    console.error('shiftUtil ' + e)
  }
}

export function getState(key) {
  return controller.getState(key)
}

export function getSequence(key) {
  return controller.getSequence(key)
}

export function callSequence(key, props) {
  controller.getSequence(key)(props)
}

